import SNAPSHOT_ROUTER from "./SnapShotRouter";
import ADMIN_ROUTER from "./AdminRouter";

export const ROUTER_KEYS = {
  SNAPSHOT: "snapshot",
  ADMIN: "admin",
};

export const SUB_DOMAIN = {
  snapshot: ROUTER_KEYS.SNAPSHOT,
  "staging-snapshot": ROUTER_KEYS.SNAPSHOT,
  admin: ROUTER_KEYS.ADMIN,
  "staging-admin": ROUTER_KEYS.ADMIN,
};

export const ROUTER = {
  [ROUTER_KEYS.SNAPSHOT]: SNAPSHOT_ROUTER,
  [ROUTER_KEYS.ADMIN]: ADMIN_ROUTER,
};

export const PATH = {
  ADMIN_SNAPSHOT: "/snapshot",
  DASHBOARD: "/dashboard",
  CSR_MAIN: "/",
  SNAPSHOT_TASK_OVERVIEW: "/tasks-overview",
  SNAPSHOT_FACILITY_TASKS: "/snapshot/facility-tasks",
  SNAPSHOT_FACILITY_COMMUNICATION_LOG: "/snapshot/facility-communication-log",
  SNAPSHOT_TASK_DETAILS: "/snapshot/task-details",
  SNAPSHOT_NEW_TASK: "/snapshot/new-task",

  SNAPSHOT_BRANDS_REPORT_DETAIL: (site_code, brands_report_id) =>
    `/snapshot/${site_code}/brands-report/${brands_report_id}`,
  SNAPSHOT_ARCHIVE_BRANDS_REPORT: (site_code) =>
    `/snapshot/${site_code}/brands-report/archive`,
};

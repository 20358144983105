import React, { useRef, useEffect } from "react";
import { FaPaperclip } from "react-icons/fa";

// type SelectImageType = {
//   file: File,
//   preview: string,
//   name: string,
// };

export function ImageUpload({ className, images = [], onSelectedImages, isMultiple = false }) {
  const hasImages = images.length > 0;
  const imageInputRef = useRef(null);

  const handleChangeImages = (event) => {
    const files = event.target.files;
    console.log("🚀 ~ handleChangeImages ~ files:", files)
    if (files && files.length) {
      const newImages = Array.from(files).map((file) => ({
        file,
        preview: URL.createObjectURL(file),
        name: file.name,
      }));
      if (typeof onSelectedImages === "function") onSelectedImages(newImages);
    }
  };

  useEffect(() => {
    const pasteHandler = (event) => {
      const items = event.clipboardData?.items;
      if (items) {
        for (let i = 0; i < items.length; i++) {
          if (items[i].type.indexOf("image") !== -1) {
            const blob = items[i].getAsFile();
            if (blob && imageInputRef.current) {
              const file = new File([blob], `pasted-image-${Date.now()}.png`, { type: "image/png" });
              const dataTransfer = new DataTransfer();
              dataTransfer.items.add(file);
              imageInputRef.current.files = dataTransfer.files;
              const newImg = { file, preview: URL.createObjectURL(file), name: file.name };
              onSelectedImages((images) => [...images, newImg]);
              event.preventDefault();
            }
          }
        }
      }
    };
    document.addEventListener("paste", pasteHandler);

    return () => {
      document.removeEventListener("paste", pasteHandler);
    };
  }, []);

  return (
    <div className={className}>
      <button type="button" className="btn btn-ghost mt-2 p-1" onClick={() => imageInputRef.current?.click()}>
        <FaPaperclip style={{ color: "#007bff" }} />
      </button>
      <input
        type="file"
        ref={imageInputRef}
        onChange={handleChangeImages}
        accept="image/*"
        className="d-none"
        multiple={isMultiple}
      />
      <div className="mt-2">
        <p className={`font-weight-500 ${hasImages ? "" : "d-none"}`}>Selected Images:</p>
        <div className="thumbnail-gallery" style={{ maxWidth: "100%" }}>
          {images.map((image, index) => (
            <a key={index} href={image.preview} target="_blank" rel="noreferrer">
              <img src={image.preview} alt={`Thumbnail ${index + 1}`} className="img-fluid" style={{ maxWidth: 150 }} />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}

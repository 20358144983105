import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import NotFound from "../pages/NotFound";
import SnapShotPage from "../pages/SnapShot";
import SnapshotClosedTasks from "../pages/SnapShot/SnapshotClosedTasks";
import SnapshotFacilityTasks from "../pages/SnapShot/SnapshotFacilityTasks";
import SnapShotMap from "../pages/SnapShot/SnapShotMap";
import SnapshotNewTask from "../pages/SnapShot/SnapshotNewTask";
import SnapshotTaskDetails from "../pages/SnapShot/SnapshotTaskDetails";
import SnapshotTasksOverview from "../pages/SnapShot/SnapshotTasksOverview";
import SnapshotCommunicationLog from "../pages/SnapShot/SnapshotCommunicationLog";
import SnapshotDashboard from "../pages/SnapShot/SnapshotDashboard";
import SnapShotDetail from "../pages/SnapShotDetail";
import AdminSnapShotDetail from "../pages/SnapShotDetail/Admin"
import SnapShotLoginPage from "../pages/SnapShot/SnapshotLoginPage";
import SnapshotArchiveBrandStandardsPage from "../pages/SnapShot/SnapshotArchiveBrandStandards";
import SnapshotBrandsReportDetail from "../pages/SnapShot/SnapshotBrandsReportDetail";
import { useSnapShotUser } from "../utilities/helper";

function SwitchPages() {
  return (
    <Switch>
      <PrivateRoute exact path="/" component={SnapShotPage} />
      <Route exact path="/login" component={SnapShotLoginPage} />
      <PrivateRoute
        exact path="/snapshot/:slug/edit"
        component={AdminSnapShotDetail}
        roles={['admin', 'ga']} // Only allow admins
      />
      <PrivateRoute
        exact path="/snapshot/:slug"
        component={SnapShotDetail} // Specify non-admin roles here
      />
      <PrivateRoute exact path="/snapshot/:slug/map" component={SnapShotMap} />
      <PrivateRoute exact path="/dashboard" component={SnapshotDashboard} />
      <PrivateRoute exact path="/tasks-overview" component={SnapshotTasksOverview} />
      <PrivateRoute exact path="/snapshot/facility-communication-log/:site_code" component={SnapshotCommunicationLog} />
      <PrivateRoute exact path="/snapshot/new-task/:site_code" component={SnapshotNewTask} />
      <PrivateRoute exact path="/snapshot/closed-task/:site_code" component={SnapshotClosedTasks} />
      <PrivateRoute exact path="/snapshot/task-details/:site_code/:task_id" component={SnapshotTaskDetails} />
      <PrivateRoute exact path="/snapshot/facility-tasks/:site_code" component={SnapshotFacilityTasks} />
      {/* brand standards */}
      <PrivateRoute exact path="/snapshot/:site_code/brands-report/archive" component={SnapshotArchiveBrandStandardsPage} />
      <PrivateRoute exact path="/snapshot/:site_code/brands-report/:brands_report_id" component={SnapshotBrandsReportDetail} />

      <Route component={NotFound} />
    </Switch>
  );
}

function PrivateRoute({ component: Component, roles, ...rest }) {
  const { user } = useSnapShotUser();
  return (
    <Route
      {...rest}
      render={(props) =>
        user && (!roles || roles.includes(user.roles)) ? (
          <Component {...props} />
        ) : roles ? (
          <Redirect to="/unauthorized" /> // Redirect unauthorized users
        ) : (
          <Redirect to={{
            pathname: "/login",
            state: { from: props.location },
          }} />
        )
      }
    />
  );
}

export default SwitchPages;

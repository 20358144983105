export const getLocationList = () => {
  if (Array.isArray(JSON.parse(localStorage.getItem("location_object")))) {
    return JSON.parse(localStorage.getItem("location_object"));
  }
  return [];
};

export const setLocationList = (object) => {
  localStorage.setItem("location_object", JSON.stringify(object));
};

export function haversineDistance(mk1, mk2) {
  var R = 3958.8; // Radius of the Earth in miles
  var rlat1 = mk1.lat * (Math.PI / 180); // Convert degrees to radians
  var rlat2 = mk2.lat * (Math.PI / 180); // Convert degrees to radians
  var difflat = rlat2 - rlat1; // Radian difference (latitudes)
  var difflon = (mk2.lng - mk1.lng) * (Math.PI / 180); // Radian difference (longitudes)

  var d =
    2 *
    R *
    Math.asin(
      Math.sqrt(
        Math.sin(difflat / 2) * Math.sin(difflat / 2) +
          Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)
      )
    );
  return d;
}

export function locationsInRange(locations, lat, lng, limitResult = 5) {
  const currentPosition = { lat, lng };
  for (const location of locations) {
    location.distance = haversineDistance(currentPosition, { lat: Number(location.lat), lng: Number(location.lng) });
  }
  let inRange = locations.filter((location) => location.distance < 10.0 && location.distance > 0);
  inRange.sort((x, y) => x.distance - y.distance);
  inRange = inRange.slice(0, limitResult);
  return inRange;
}

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function isSequentialIncrease(number) {
  if (!number || isNaN(number)) {
    return true;
  }
  const strNum = number + "";
  const isSequentialIncrease = true;
  for (let i = 1; i < strNum.length; ++i) {
    if (strNum.charAt(i) <= strNum.charAt(i - 1)) {
      return false;
    }
  }
  return isSequentialIncrease;
}

function isSequentialDecrease(number) {
  if (!number || isNaN(number)) {
    return true;
  }
  const strNum = number + "";
  const isSequentialDecrease = true;
  for (let i = 1; i < strNum.length; ++i) {
    if (strNum.charAt(i) >= strNum.charAt(i - 1)) {
      return false;
    }
  }
  return isSequentialDecrease;
}

export const getRandomIntWithoutSequential = (min = 100001, max = 999998) => {
  let int = getRandomInt(min, max);
  while (isSequentialIncrease(int) || isSequentialDecrease(int)) {
    int = getRandomInt(min, max);
  }
  return int;
};

export const locale = {
  // Options.jsx
  items_per_page: "/ page",
  jump_to: "Go to",
  jump_to_confirm: "confirm",
  page: "Page",

  // Pagination.jsx
  prev_page: "Previous Page",
  next_page: "Next Page",
  prev_5: "Previous 5 Pages",
  next_5: "Next 5 Pages",
  prev_3: "Previous 3 Pages",
  next_3: "Next 3 Pages",
  page_size: "Page Size",
};

export const convertJsonToCsv = (jsonData, columnKeys = null) => {
  const separator = ",";
  const keys = columnKeys || Object.keys(jsonData[0]);
  const csvContent = [
    keys.join(separator),
    ...jsonData.map((item) =>
      keys.map((key) => JSON.stringify(Array.isArray(item[key]) ? item[key].join(", ") : item[key])).join(separator)
    ),
  ].join("\n");

  return csvContent;
};

export const downloadCsvFile = (csvContent, fileName) => {
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  link.style.display = "none";

  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};
